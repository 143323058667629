<template>
    <div class="signup-page">
        <div class="bg-video">
            <video autoPlay loop muted :controls=false playsInline>
                <source src='/assets/video/instories-ru-bg1.mp4' type='video/mp4'/>
            </video>
        </div>
        <div class="bg-gradient"/>
        <div class="content">
            <div class="logo-ru">
                <img src="/assets/images/logo_white_ru.png" alt=""/>
            </div>
            <div class="title">{{ $t('signup.title') }}</div>
            <div class="description">{{ $t('signup.description') }}</div>
            <AuthButton :need-auth="needAuth"/>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue';
import {useRoute} from 'vue-router';
import {authApi, AuthButton} from '/src-new-version/modules/auth';
import {onelinkActions} from "/src-new-version/modules/onelink";
import {routerActions} from '/src-new-version/app/router';

const needAuth = ref(true);

const route = useRoute();
const {source, token, new_session} = route.query;
localStorage.setItem('source', source);
const sourceIsApp = source === 'app';

onBeforeMount(async () => {
    if (new_session) {
        const special = localStorage.getItem('special')
        localStorage.clear()
        if (special) {
            localStorage.setItem('special', special);
        }
        routerActions.routeTo('Signup');
        return
    }
    if (localStorage.getItem('status') === "su") {
        routerActions.routeByStatus();
        return;
    }
    if (sourceIsApp && token) {
        const oauth_id = await authApi.checkAuth(token);
        if (oauth_id) {
            localStorage.setItem('oauth_id', oauth_id);
            needAuth.value = false;
        }
    }
    onelinkActions.parseSearchParams();
});
</script>

<style scoped lang="scss">
.signup-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bg-video {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        justify-content: center;

        video {
            object-fit: cover;
            width: 100%;
            max-width: $container-max-width;
            height: calc(100% - 160px);
            object-position: right 35% top 0;
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
        }
    }

    .bg-gradient {
        position: absolute;
        z-index: 2;
        inset: auto 0 0 0;
        height: 380px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 36.46%, #000 82.29%);
    }

    .content {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-width: $content-max-width;
        padding: 20px 0 106px;

        .logo-ru {
            flex-grow: 1;
            display: flex;

            img {
                height: 24px;
            }
        }

        .title {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #EFEFF2;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.5px;
            padding-bottom: 12px;
            white-space: pre-line;
        }

        .description {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #A1A1AA;
            text-align: center;
            font-size: 17px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
            padding-bottom: 32px;
        }

        .button {
            width: 100%;
            max-width: calc(100vw - 40px);
        }

        @media (max-width: 389px) {
            .title {
                font-size: 17px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.34px;
            }

            .description {
                font-size: 14px;
                line-height: 20px;
            }
        }

        @media (min-width: 744px) {
            padding-bottom: 62px;
        }
    }
}
</style>
