<template>
    <div class="subscription-page">
        <div class="bg-video">
            <video autoPlay loop muted :controls=false playsInline>
                <source src='/assets/video/instories-ru-bg2.mp4' type='video/mp4'/>
            </video>
        </div>
        <PurchaseForm/>
    </div>
</template>

<script setup>
import {onBeforeMount} from 'vue';
import {billingActions, PurchaseForm} from '/src-new-version/modules/billing';
import {routerActions} from '/src-new-version/app/router';

onBeforeMount(() => {
    billingActions.clearSelectedProduct();
    routerActions.routeIfCannotSubscribe();
});
</script>

<style scoped lang="scss">
.subscription-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bg-video {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        justify-content: center;

        video {
            object-fit: cover;
            width: 100%;
            max-width: $container-max-width;
            object-position: top center;
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
        }
    }
}
</style>
