import {routerActions} from '/src-new-version/app/router';


const ONELINK_BASE = process.env.VUE_APP_ONELINK;
const ONELINK_PID = process.env.VUE_APP_ONELINK_PID;
const ONELINK_CAMPAIGN = process.env.VUE_APP_ONELINK_CAMPAIGN;

export const setToStorage = (key, value) => {
    if (value) {
        localStorage.setItem(key, value);
    } else {
        localStorage.removeItem(key);
    }
    localStorage.setItem('timestamp', new Date().getTime().toString());
};

export const createDefaultIfNeeded = () => {
    if (localStorage.getItem('onelink')) {
        return;
    }

    let onelink = new URL(ONELINK_BASE);
    if (ONELINK_PID) {
        onelink.searchParams.set('pid', ONELINK_PID);
    }
    if (ONELINK_CAMPAIGN) {
        onelink.searchParams.set('c', ONELINK_CAMPAIGN);
    }

    if (localStorage.getItem('oauth_id')) {
        onelink.searchParams.set('deep_link_value', localStorage.getItem('oauth_id'));
    }
    setToStorage("onelink", onelink);
};

const setParam = (key, value) => {
    let onelink = new URL(localStorage.getItem('onelink'));
    onelink.searchParams.set(key, value);
    setToStorage("onelink", onelink.href);
};

export const updateAfterAuth = (data) => {
    createDefaultIfNeeded();
    setParam("deep_link_value", data.oauth_id);
    const status = data.bank131_status;
    if (data.oauth_id && status) {
        setToStorage("status", status);
        setToStorage("oauth_id", data.oauth_id);
    }
};

export const checkStorageTimeout = function () {
    const lastTimestamp = localStorage.getItem('timestamp');
    const timestamp = new Date().getTime();
    const deadline = parseInt(lastTimestamp, 10) + (24 * 60 * 60 * 1000);
    if (lastTimestamp && timestamp >= deadline) {
        const specialBackup = localStorage.getItem('special');
        const clickIdBackup = localStorage.getItem('click_id');
        localStorage.clear();
        if (specialBackup) {
            localStorage.setItem('special', specialBackup);
        }
        if (clickIdBackup) {
            localStorage.setItem('click_id', clickIdBackup);
        }
    }
};

export const goToInstories = () => {
    const sourceIsApp = localStorage.getItem('source') === 'app';
    if (sourceIsApp) {
        console.log('goToInstories', `instoris://action?id=webPaywall&oauth=${localStorage.getItem('oauth_id')}&result=1`);
        window.location = `instoris://action?id=webPaywall&oauth=${localStorage.getItem('oauth_id')}&result=1`;
        return;
    }
    console.log('goToInstories', localStorage.getItem('onelink'));
    window.location = localStorage.getItem('onelink');
};

export const parseSearchParams = () => {
    const params = new URLSearchParams(window.location.search);
    const oauth_id = params.get("oauth_id") || localStorage.getItem('oauth_id');
    const status = params.get("status") || localStorage.getItem('status');

    if (localStorage.getItem('onelink') && oauth_id && status) {
        const onelink = new URL(localStorage.getItem('onelink'));
        onelink.searchParams.set("deep_link_value", oauth_id);
        setToStorage("status", status);
        setToStorage("oauth_id", oauth_id);
        setToStorage("onelink", onelink.href);
        routerActions.routeByStatus();
    }
};
