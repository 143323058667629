<template>
    <div class="payment-page">
        <div class="back-btn" @click="routerActions.routeTo('Purchase')">
            <IcArrowLeft class="arrow-left"/>
        </div>
        <div class="title">{{ $t('payment.page_title') }}</div>
        <PaymentForm/>
    </div>
</template>

<script setup>
import {onBeforeMount} from 'vue';
import {routerActions} from '/src-new-version/app/router';
import {PaymentForm} from "/src-new-version/modules/billing";
import IcArrowLeft from "/src-new-version/shared/icons/IcArrowLeft.vue";

onBeforeMount(() => {
    routerActions.routeIfCannotSubscribe();
});
</script>

<style scoped lang="scss">
.payment-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #151518;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 46px;

    .back-btn {
        color: #FFF;
        position: absolute;
        top: 12px;
        left: 12px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
    }

    .title {
        width: 100%;
        max-width: calc(100vw - 40px);
        color: #EFEFF2;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.3px;
        margin-bottom: 40px;
        white-space: pre-line;
    }
}
</style>
