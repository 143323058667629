import {onelinkActions} from '/src-new-version/modules/onelink';
import {routerActions} from '/src-new-version/app/router';

const APPLE_SCRIPT_ID = 'appleScriptId';
const APPLE_AUTH_URI = process.env.VUE_APP_APPLEID_AUTH_REQUEST_URI;
const SPECIAL_TOKEN = process.env.VUE_APP_SPECIAL_TOKEN;

export const checkSpecial = () => {
    let url = new URL(window.location.href);
    let special = url.searchParams.get("special");
    if (special) {
        localStorage.setItem('special', special);
    }
};

const initAppleJS = () => {
    const redirect = window.location.origin + '/purchase';
    const special = localStorage.getItem('special') || SPECIAL_TOKEN;
    const state = {special, redirect};
    if (localStorage.getItem('click_id')) {
        state.click_id = localStorage.getItem('click_id');
    }
    window.AppleID.auth.init({
        clientId: process.env.VUE_APP_APPLEID_AUTH_CLIENT_ID,
        scope: "name email",
        redirectURI: process.env.VUE_APP_APPLEID_AUTH_REDIRECT_URI,
        state: JSON.stringify(state),
        usePopup: process.env.VUE_APP_APPLEID_USE_POPUP === "true"
    });
};

const loadAppleJS = (lang) => {
    const appleScriptSrc = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${lang}/appleid.auth.js`;
    const appleScript = document.createElement('script');
    appleScript.setAttribute('src', appleScriptSrc);
    appleScript.setAttribute('id', APPLE_SCRIPT_ID);
    document.head.appendChild(appleScript);

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
        let body = event.detail;
        body.special = localStorage.getItem('special') || SPECIAL_TOKEN;
        if (localStorage.getItem('click_id')) {
            body.click_id = localStorage.getItem('click_id');
        }
        const request = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        };
        fetch(APPLE_AUTH_URI, request)
            .then(response => response.json())
            .then(data => {
                onelinkActions.updateAfterAuth(data);
                routerActions.routeByStatus('Signup');
            });
    });

    document.addEventListener('AppleIDSignInOnFailure', (error) => {
        console.log('AppleIDSignInOnFailure', error);
    });

    appleScript.onload = initAppleJS;
};

export const initAppleAuth = (lang) => {
    const appleScriptElement = document.getElementById(APPLE_SCRIPT_ID);
    if (appleScriptElement) {
        initAppleJS();
    } else {
        loadAppleJS(lang);
    }
};

export const signIn = () => {
    if (process.env.VUE_APP_ENV === 'development') {
        localStorage.setItem('oauth_id', '001764.0af9787d5bfd447ea437371f73e7d16c.1523');
        onelinkActions.createDefaultIfNeeded();
    }
    if (!window.AppleID || !window.AppleID.auth || !window.AppleID.auth.signIn) {
        return;
    }
    window.AppleID.auth.signIn();
};
