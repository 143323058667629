<template>
    <router-view/>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
@import "./style/normalize.scss";
@import "./style/common.scss";
</style>
