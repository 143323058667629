import ApiService from "/src-new-version/services/ApiService";

const PRICES_URL = process.env.VUE_APP_BANK_131_PLAN_REQUEST_URI;
const SUBSCRIBE_URL = process.env.VUE_APP_BANK_131_SUBSCRIBE_REQUEST_URI;
const DEFAULT_ERR_MSG = 'Произошла ошибка. Попробуйте снова';

export const getPrices = async () => {
    let oauth_id = process.env.NODE_ENV === "local"
        ? '001764.0af9787d5bfd447ea437371f73e7d16c.1523'
        : localStorage.getItem('oauth_id');
    if (!oauth_id) {
        throw new Error('Missing Oauth ID');
    }

    const {data} = await ApiService.anonInstance.get(PRICES_URL);
    const result = {};
    data.prices.forEach((item) => {
        if (item.period === 'year') {
            result.year = {
                ...item,
                price: parseInt(item.price, 10)
            };
        } else if (item.period === 'month') {
            result.month = {
                ...item,
                price: parseInt(item.price, 10)
            };
        }
    });
    return result;
};

export const startPaymentSession = async (price_id, use_trial, consent) => {
    let oauth_id = process.env.NODE_ENV === "local"
        ? '001764.0af9787d5bfd447ea437371f73e7d16c.1523'
        : localStorage.getItem('oauth_id');
    if (!oauth_id) {
        throw new Error('Missing Oauth ID');
    }

    const success_url = window.location.origin + '/payment?status=success';
    const failure_url = window.location.origin + '/payment?status=failed';

    const payload = {oauth_id, price_id, use_trial, consent, success_url, failure_url};
    const {data} = await ApiService.anonInstance.post(SUBSCRIBE_URL, payload);

    return data;
};
