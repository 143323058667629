<template>
    <div class="successful-payment-page">
        <div class="content">
            <div class="logo-ru">
                <img class="name" src="/assets/images/logo_dark_ru.png" alt=""/>
                <div class="suffix-wrap">
                    <img class="suffix" src="/assets/images/suffix_pro_gold.png" alt=""/>
                </div>
            </div>
            <div class="title">
                {{ $t('subscription.success') }}
            </div>
            <div class="feature-list">
                <div v-for="{title, subtitle, icon, bgImageUrl} in features" :key="title" class="feature-item">
                    <div class="icon-wrap" v-html="icon" :style="{backgroundImage: `url(${bgImageUrl})`}"/>
                    <div class="text">
                        <div class="title">{{ title }}</div>
                        <div class="subtitle">{{ subtitle }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="redirect-btn">
            <Button class="button_black" :title="$t('button.go_to_instories')" @click="onelinkActions.goToInstories"/>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount} from "vue";
import Button from '/src-new-version/shared/components/Button.vue';
import {featureConstants} from '/src-new-version/modules/feature';
import {routerActions} from '/src-new-version/app/router';
import {onelinkActions} from '/src-new-version/modules/onelink';
import {billingActions} from "/src-new-version/modules/billing";

const features = featureConstants.features;
onBeforeMount(() => {
    billingActions.clearSelectedProduct();
    routerActions.routeIfCannotSubscribe('Success');
});
</script>

<style scoped lang="scss">
.successful-payment-page {

    $container-max-width: 744px;
    $content-max-width: 390px;

    background-color: #FFF;
    width: 100vw;
    height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding-bottom: 80px;

    .content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: $content-max-width;

        .logo-ru {
            display: flex;
            gap: 8px;
            height: 168px;
            align-items: center;
            flex-shrink: 0;

            img.name {
                height: 40px;
            }

            .suffix-wrap {
                height: 40px;

                img.suffix {
                    height: 26px;
                    position: relative;
                    top: 8px;
                }
            }
        }

        > .title {
            width: 100%;
            max-width: calc(100vw - 40px);
            color: #141417;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.38px;
            margin-bottom: 28px;
        }

        .feature-list {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width: 100%;
            max-width: calc(100vw - 40px);

            .feature-item {
                display: flex;
                gap: 16px;
                width: 100%;
                padding: 4px 0;
                align-items: center;

                .icon-wrap {
                    width: 52px;
                    height: 52px;
                    border-radius: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    flex-shrink: 0;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 2px;

                    .title {
                        color: #141417;
                        font-size: 17px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: -0.34px;
                    }

                    .subtitle {
                        color: #81818F;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .redirect-btn {
        position: fixed;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        display: flex;
        justify-content: center;
        padding: 12px 0;

        .button {
            width: $content-max-width;
            max-width: calc(100vw - 40px);
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06), 0 4px 8px 0 rgba(0, 0, 0, 0.14);
        }
    }

    @media (max-width: 389px) {
        .content {
            .logo-ru {
                height: 110px;

                img.name {
                    height: 30px;
                }

                .suffix-wrap {
                    height: 30px;

                    img.suffix {
                        height: 20px;
                        top: 6px;
                    }
                }
            }

            > .title {
                font-size: 17px;
                font-weight: 500;
                letter-spacing: -0.34px;
                white-space: pre-line;
                margin-bottom: 20px;
            }

            .feature-list {
                gap: 0;
                max-width: calc(100vw - 32px);

                .feature-item {
                    padding: 6px 0;
                }
            }
        }

        .redirect-btn {
            .button {
                max-width: calc(100vw - 32px);
            }
        }
    }

    @media (min-width: 744px) {
        padding-bottom: 172px;

        .content {
            .logo-ru {
                height: 146px;
                padding-top: 64px;
                align-items: flex-start;

                img.name {
                    height: 30px;
                }

                .suffix-wrap {
                    height: 30px;

                    img.suffix {
                        height: 20px;
                        top: 6px;
                    }
                }
            }

            > .title {
                white-space: pre-line;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.5px;
            }

            .feature-list {
                .feature-item {
                    padding: 6px 0;
                }
            }
        }

        .redirect-btn {
            padding-bottom: 62px;
        }
    }
}
</style>
