<template>
    <svg
        class="icon-loader"
        :width="size"
        :height="size"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <clipPath :id="id">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.60278 28.9604C6.47229 25.7854 7.02323 22.7698 8.1251 20.0298C8.45856 19.2179 8.21209 18.2756 7.53067 17.6957L5.5009 16.0284C4.47152 15.1731 2.89121 15.521 2.35477 16.7533C0.774456 20.3632 -0.0809441 24.3791 0.0060463 28.5834C0.310513 43.632 12.6486 55.839 27.7123 55.9985C43.3705 56.158 56.158 43.3855 55.9985 27.7136C55.839 12.7085 43.733 0.399927 28.7417 0.00848958C24.5662 -0.0929942 20.5936 0.718876 16.9981 2.25563C15.7657 2.77755 15.3888 4.35779 16.2442 5.40163L17.9115 7.4458C18.4769 8.12719 19.4048 8.37365 20.2312 8.0547C22.9569 6.98187 25.958 6.45996 29.0897 6.61943C40.0359 7.17034 48.8654 16.0284 49.3873 26.9742C49.9817 39.5872 39.6009 49.982 27.0019 49.4021C15.9832 48.8947 7.08122 39.9786 6.60278 28.9604ZM7.28524 11.8966L9.40199 13.8538C10.2574 14.6367 11.5912 14.5932 12.3741 13.7523C12.8671 13.2159 13.3745 12.723 13.8965 12.2446C14.7664 11.4617 14.8099 10.1134 14.027 9.25804L12.0697 7.12688C11.3013 6.27151 9.98192 6.19903 9.12652 6.9674C8.4451 7.5908 7.80718 8.2287 7.18375 8.89559C6.38634 9.76545 6.41534 11.0992 7.28524 11.8966Z"
                />
            </clipPath>
        </defs>
        <foreignObject x="0" y="0" width="56" height="56" :clip-path="`url(#${id})`">
            <div class="gradient"/>
        </foreignObject>
    </svg>
</template>

<script setup>
defineProps({
    size: {
        type: Number,
        default: 56
    },
    color: {
        type: String,
        default: '#141417'
    }
});

const id = Date.now() + Math.random() * 1000;
</script>

<style scoped lang="scss">
.gradient {
    width: 100%;
    height: 100%;
    background: conic-gradient(from 328deg, #ff79ff00 4%, #a941ff80 20%, #f12bff 100%);
}

.icon-loader {
    animation: rotation 1s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}
</style>
