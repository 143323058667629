<template>
    <div class="payment-form">
        <div v-if="name" class="product-name">{{ name }}</div>
        <div v-if="priceInfo" class="price-info">{{ priceInfo }}</div>
        <div v-if="state === 'success'" class="success-wrap">
            <img src="/assets/svg/IllustrationBilling.svg"/>
            <div>{{ $t('payment.success_notification') }}</div>
            <Button class="button_white" :title="$t('payment.continue_caption')"
                    @click="handleContinue"/>
        </div>
        <div v-else :class="{'widget-wrap': true, 'loading-failed': state === 'loadingFailed'}">
            <div v-show="state === 'loading'" class="loader">
                <IcLoader/>
            </div>
            <div v-show="state === 'loadingFailed'" class="failed-info">
                <div>{{ $t('payment.loading_failed_notification') }}</div>
                <Button class="button_white" :title="$t('payment.try_again_caption')" @click="tryStartSession"/>
            </div>
            <div v-show="['ready', 'inProgress', 'success', 'failed'].includes(state)"
                 id="bank131-payment-form"/>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from 'vue-i18n';
import {onBeforeMount, onMounted, ref} from 'vue';
import {firstCharToLowerCase} from "/src-new-version/shared/utils/firstCharToLowerCase";
import Button from '/src-new-version/shared/components/Button.vue';
import PaymentService from "/src-new-version/services/PaymentService";
import {routerActions} from "/src-new-version/app/router";
import {useBillingStore} from "../store/billingStore";
import * as billingApi from "../api/billingApi";
import IcLoader from "/src-new-version/shared/icons/IcLoader.vue";
import {useNotifications} from "/src-new-version/modules/notification";
import '../style/bank-131-widget.scss';
import {useRoute} from "vue-router";
import {billingActions} from "../index";

const {t} = useI18n();

const {name, id: product_id} = useBillingStore(state => state.product);
const priceTitle = useBillingStore(state => state.priceTitle);
const trialSubtitleText = useBillingStore(state => state.trialSubtitleText);
const isTrialSelected = useBillingStore(state => state.isTrialSelected);
const userIsAgree = useBillingStore(state => state.userIsAgree);

const {notifySuccess, notifyError} = useNotifications();

const priceInfo = trialSubtitleText.value
    ? `${trialSubtitleText.value}. ${t('common.then')} ${firstCharToLowerCase(priceTitle.value)}`
    : priceTitle;

const route = useRoute();
const {status} = route.query;
const state = ref(status || 'loading'); // loading | loadingFailed | ready | inProgress | success | failed

const tryStartSession = async () => {
    state.value = 'loading';
    try {
        const {widget_token} = await billingApi.startPaymentSession(product_id.value, isTrialSelected.value, userIsAgree.value);
        PaymentService.bank131Client.init({
            onReady: (...args) => {
                console.log('onReady', args);
                state.value = 'ready';
            },
            onPaymentStart: (...args) => {
                console.log('onPaymentStart', args);
                state.value = 'inProgress';
            },
            onPaymentSuccess: (...args) => {
                console.log('onPaymentSuccess', args);
                state.value = 'success';
            },
            onPaymentFail: (...args) => {
                console.log('onPaymentFail', args);
                state.value = 'failed';
            },
        });
        PaymentService.bank131Client.setTokenAndTryRender(widget_token);
    } catch (e) {
        state.value = 'loadingFailed';
        notifyError(t('payment.loading_failed_notification'));
    }
};

const handleContinue = () => {
    billingActions.saveSubscribedStatus();
    routerActions.routeTo('Success');
};

onBeforeMount(() => {
    if (!product_id) {
        routerActions.routeTo('Purchase');
        return;
    }
    if (state.value === 'failed') {
        notifyError(t('payment.failed_notification'));
        routerActions.routeTo('Payment');
    }
});

onMounted(() => {
    if (state.value !== 'success') {
        tryStartSession();
    } else {
        notifySuccess(t('payment.success_notification'));
        routerActions.routeTo('Payment');
    }
});
</script>

<style scoped lang="scss">
.payment-form {
    width: 400px;
    max-width: calc(100vw - 40px);

    .product-name {
        color: #FFF;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.34px;
        margin-bottom: 8px;
        white-space: nowrap;
    }

    .price-info {
        color: #A1A1AA;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.34px;
        margin-bottom: 24px;
    }

    .widget-wrap {
        //background-color: rgba(240, 128, 128, 0.43);
        transition: 300ms;
        min-height: 400px;
        display: flex;
        border-radius: 24px;

        &.loading-failed {
            background-color: rgba(181, 181, 181, 0.1);
        }

        .loader {
            //background-color: rgba(32, 65, 126, 0.43);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .failed-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
            color: #A1A1AA;
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.34px;
            text-align: center;
            padding: 24px;
        }
    }

    .success-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFF;
        gap: 24px;

        img {
            height: 100px;
        }
    }
}
</style>
