import {createRouter, createWebHistory} from "vue-router";
import SignupPage from "/src-new-version/pages/SignupPage.vue";
import PurchasePage from "/src-new-version/pages/PurchasePage.vue";
import PaymentPage from "/src-new-version/pages/PaymentPage.vue";
import SuccessPage from "/src-new-version/pages/SuccessPage.vue";

const routes = [
    {
        path: "/",
        name: "Signup",
        component: SignupPage
    },
    {
        path: "/purchase",
        name: "Purchase",
        component: PurchasePage
    },
    {
        path: "/payment",
        name: "Payment",
        component: PaymentPage
    },
    {
        path: "/success",
        name: "Success",
        component: SuccessPage
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
