import router from '/src-new-version/app/router';

export const routeTo = (name) => {
    router.push({name});
};

export const routeByStatus = (name) => {
    if (localStorage.getItem('oauth_id')) {
        if (localStorage.getItem('status') === "su") {
            routeTo('Success');
        } else {
            routeTo('Purchase');
        }
    } else if (name) {
        routeTo(name);
    }
};

export const routeIfCannotSubscribe = (name) => {
    if (!localStorage.getItem('oauth_id')) {
        routeTo('Signup');
    } else if (localStorage.getItem('status') === "su") {
        routeTo('Success');
    } else if (name === 'Success' && localStorage.getItem('status') !== "su") {
        routeTo('Purchase');
    }
};
