<template>
    <div class="content">
        <div class="title">
            {{ $t('subscription.title') }}
        </div>
        <div class="subscription-options" :class="{hiddens: !priceTextYear || !priceTextMonth}">
            <RadioButton
                v-model="selectedSubscription"
                class="yearly"
                option-value="year"
                :label-text="priceTextYear"
                :side-text="discountTextYear"/>
            <RadioButton
                v-model="selectedSubscription"
                class="monthly"
                option-value="month"
                :label-text="priceTextMonth"
                @update:model-value="handleChangeMonthly"/>
        </div>
        <div class="checkbox-wrap" :class="{hidden: !isTrialAvailable}">
            <Switcher v-if="isTrialAvailable" v-model="isTrialSelected" @update:model-value="handleChangeTrial"/>
        </div>
        <Button
            class="button_white"
            :title="$t('button.continue')"
            :subtitle="isTrialSelected ? trialSubtitleText : ''"
            outlined
            :loading="isLoading"
            @click="handleClickContinue"/>
        <div class="terms-checkbox" @click="userIsAgree = !userIsAgree">
            <IcCheck :active="userIsAgree" :color="!userIsAgree && highlightCheckbox ? '#FFF' : '#81818F'"/>
            <label for="terms-checkbox-input">
                {{ $t('text.terms_checkbox_label') }}
                <a @click.stop href="https://instoris.ru/terms" target="_blank" ref="noopener noreferrer">
                    {{ $t('common.terms_of_offer') }}
                </a>
            </label>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import RadioButton from '/src-new-version/shared/components/RadioButton';
import Button from '/src-new-version/shared/components/Button';
import Switcher from '/src-new-version/shared/components/Switcher';
import IcCheck from '/src-new-version/shared/components/IcCheck';
import {billingActions, usePrices} from '/src-new-version/modules/billing';
import {routerActions} from "/src-new-version/app/router";

const {
    prices,
    priceTextYear,
    priceTextMonth,
    discountTextYear,
    isTrialAvailable,
    trialSubtitleText
} = usePrices();

const selectedSubscription = ref('year');
const isTrialSelected = ref(true);
const isLoading = ref(false);
const userIsAgree = ref(true);
const highlightCheckbox = ref(false);

const handleChangeMonthly = (value) => {
    if (!value) {
        return;
    }
    isTrialSelected.value = false;
};

const handleChangeTrial = (value) => {
    if (value === 'year') {
        return;
    }
    selectedSubscription.value = 'year';
};

const handleClickContinue = async () => {
    const data = prices.value;
    if (!data) {
        return;
    }
    const product = data[selectedSubscription.value];
    if (!product) {
        return;
    }
    if (!userIsAgree.value) {
        highlightCheckbox.value = true;
        setTimeout(() => {
            highlightCheckbox.value = false;
        }, 500);
        return;
    }
    const priceTitle = {
        year: priceTextYear,
        month: priceTextMonth
    }[selectedSubscription.value];
    const name = {
        year: 'Инсторис ПРО - годовая подписка',
        month: 'Инсторис ПРО - месяц'
    }[selectedSubscription.value];
    billingActions.setSelectedProduct({
        product: {...product, name},
        isTrialSelected: isTrialSelected.value,
        userIsAgree: userIsAgree.value,
        priceTitle: priceTitle.value,
        trialSubtitleText: isTrialSelected.value ? trialSubtitleText.value : ''
    });
    routerActions.routeTo('Payment');
};
</script>

<style scoped lang="scss">
.content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    max-width: 390px;
    padding: 20px 0 46px;

    .title {
        width: 100%;
        max-width: calc(100vw - 40px);
        color: #EFEFF2;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.3px;
        margin-bottom: 40px;
        white-space: pre-line;
    }

    .subscription-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 44px;
        transition: 300ms;

        .radio-button {
            width: 100%;
            max-width: calc(100vw - 40px);

            &.yearly {
                .main-info {
                    .label {
                        font-size: 15px;
                        line-height: 20px;
                        letter-spacing: -0.23px;
                    }
                }
            }
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .checkbox-wrap {
        display: flex;
        width: 100%;
        max-width: calc(100vw - 40px);
        height: 32px;
        margin-bottom: 28px;
        padding-left: 16px;

        .checkbox-label {
            color: #FFF;
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.34px;
            flex-grow: 1;
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .button {
        width: 100%;
        max-width: calc(100vw - 40px);
        height: 62px;
        margin-bottom: 24px;
    }

    .terms-checkbox {
        width: 100%;
        max-width: calc(100vw - 40px);
        display: flex;
        gap: 8px;
        cursor: pointer;

        svg {
            flex-shrink: 0;
        }

        input {
            display: none;
        }

        label {
            color: #A1A1AA;
            font-size: 9px;
            line-height: 12px;
            letter-spacing: 0.2px;
            cursor: pointer;

            a {
                color: #EFEFF2;
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    color: #FFF;
                }
            }
        }
    }

    @media (max-width: 389px) {
        .title {
            font-size: 17px;
            font-weight: 500;
            line-height: 24px;
        }

        .subscription-options {
            margin-bottom: 36px;

            .radio-button {
                max-width: calc(100vw - 32px);

                &.yearly,
                &.monthly {
                    .main-info {
                        .label {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .checkbox-wrap {
            padding-left: 12px;
        }

        .button {
            height: 56px;
        }
    }

    @media (min-width: 744px) {
        padding-bottom: 62px;
        gap: 12px;

        .title {
            letter-spacing: 0.5px;
        }

        .subscription-options {
            margin-bottom: 96px;
        }

        .checkbox-wrap {
            margin-bottom: 46px;
            padding-left: 0;
        }
    }
}
</style>
