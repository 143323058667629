<template>
    <Button class="button_white" :title="$t('button.sign_in')" @click="pressSignIn">
        <template #icon>
            <IcApple/>
        </template>
    </Button>
</template>

<script setup>
import {useI18n} from 'vue-i18n';
import Button from '/src-new-version/shared/components/Button.vue';
import IcApple from '/src-new-version/shared/icons/IcApple.vue';
import {authActions} from '/src-new-version/modules/auth';
import {routerActions} from "/src-new-version/app/router";

const props = defineProps({
    needAuth: {
        type: Boolean,
        required: true,
        default: true
    }
});

const {t} = useI18n();

authActions.initAppleAuth(t("apple.script_lang"));
const pressSignIn = () => {
    if (props.needAuth) {
        authActions.signIn();
    } else {
        routerActions.routeTo('Purchase');
    }
};
</script>

<style scoped lang="scss">

</style>
