import {createI18n} from "vue-i18n";

const messages = {
    ru: {
        apple: {
            script_lang: "ru_RU"
        },
        button: {
            next: "Далее",
            continue: "Продолжить",
            get_app: "Скачать приложение",
            sign_in: "Войти с Apple",
            sign_in_2: "Войти",
            yes: "Yes",
            yes_long: "Yes, I’m sure",
            no: "No",
            no_long: "No, I’m not",
            go_to_instories: "Перейти в Инсторис"
        },
        text: {
            boost_you: "Создавай моушн для SMM <br>в Инсторис",
            best_templates: "Лучшие шаблоны <br>для ваших задач",
            download_app: "Скачайте приложение чтобы начать",
            unsuccess: "Не удалось :( <br>Попробуйте ещё раз",
            success: "Поздравляем!",
            success_subtitle: "Ваш пробный период открыт. <br>Скачайте приложение, чтобы начать.",
            welcome: "Добро пожаловать!",
            welcome_subtitle: "У вас уже есть аккаунт. <br>Скачайте приложение, чтобы начать.",
            upset: "We are very upset :(",
            unsubscribe: "You sure you want to unsubscribe?",
            subscription_cancelled: "Subscription is cancelled ☹️",
            subscription_restart: "Want to restart subscription?",
            page_title: "Развивайте свой Instagram вместе с <span> Инсторис </span>",
            auth: "Авторизуйтесь, чтобы получить доступ к подписке",
            success_pay: "Поздравляем! Подписка <span> Инсторис </span> оформлена!",
            go_to_app: "Перейдите по кнопке в приложение, чтобы не проходить повторную авторизацию",
            templates: "500+ шаблонов",
            terms_checkbox_label: "Я соглашаюсь на автоматические списания, обработку персональных данных и принимаю"
        },
        link: {
            terms_conditions: "Условия использования",
            privacy_policy: "Конфиденциальность",
            accept_text: "Продолжая, вы принимаете нашу"
        },
        common: {
            and: "и",
            then: "Далее",
            year: "год",
            month: "месяц",
            week: "неделя",
            discount: "скидка",
            terms_of_offer: "Условия лицензионного соглашения"
        },
        price: {
            three_days_free: "3 дня бесплатно",
            days_free: "дня бесплатно",
            free_trial_days: "-дневная пробная версия",
            description: "Instories PRO subscription"
        },
        signup: {
            title: 'Создавайте уникальные\nReels, Stories, Posts',
            description: 'Авторизуйтесь, чтобы подключить Pro лицензию к вашему аккаунту. Это безопасно и займёт одну минуту.'
        },
        subscription: {
            title: 'Оформи подписку и получи неограниченный доступ ко всем премиум инструментам Инсторис',
            trial_title: 'Пробный период',
            price_label_year: 'Ежегодно {price} RUB\n({perMonth} RUB / мес)',
            price_label_month: 'Ежемесячно {price} RUB',
            trial_days: "0 дней бесплатно | {days} день бесплатно | {days} дня бесплатно | {days} дней бесплатно",
            success: "Подписка оформлена!\nТеперь у вас есть доступ\nк премиум инструментам:"
        },
        payment: {
            page_title: 'Способ оплаты',
            loading_failed_notification: 'При попытке соединения произошла ошибка',
            try_again_caption: 'Повторить попытку',
            continue_caption: 'Продолжить',
            failed_notification: 'При попытке оплаты произошла ошибка',
            success_notification: '✅ Оплата прошла успешно',
        },
        feature: {
            templates_title: '500+ шаблонов Story',
            templates_subtitle: 'Создание трендового контента',
            reels_title: '200+ шаблонов Reels',
            reels_subtitle: 'Легкое редактирование и настройка',
            bg_title: 'Удаление и замена фона',
            bg_subtitle: 'За пару секунд',
            font_title: 'Библиотека шрифтов',
            font_subtitle: 'На кириллице',
            ai_title: 'Генерация постов с чатом GPT',
            ai_subtitle: 'Создание и улучшение текстов',
            filter_title: 'Трендовые фильтры',
            filter_subtitle: 'Чтобы контент выглядел профессионально',
            logo_title: 'Ваш логотип',
            logo_subtitle: 'Загрузите png',
            sticker_title: 'Более 1000+ стикеров',
            sticker_subtitle: 'На любой вкус',
        }
    },
};

export const i18n = createI18n({
    locale: navigator.language.split('-')[0],
    pluralizationRules: {
        ru: function (choice, choicesLength) {

            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    },
    fallbackLocale: 'ru',
    messages,
});
