<template>
    <div class="switcher-wrap" :class="{active: modelValue}" @click="handleClick">
        <div class="switcher-label">
            {{ $t('subscription.trial_title') }}
        </div>
        <div class="switcher"/>
    </div>
</template>

<script>
export default {
    name: "Switcher",
    props: {
        modelValue: Boolean
    },
    methods: {
        handleClick() {
            this.$emit('update:model-value', !this.modelValue);
        }
    }
};
</script>

<style lang="scss">
.switcher-wrap {

    $switcher-width: 52px;
    $switcher-height: 32px;
    $amplitude: $switcher-width - $switcher-height;
    $slider-size: 28px;
    $padding: ($switcher-height - $slider-size) / 2;

    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    .switcher-label {
        flex-grow: 1;
        color: #FFF;
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.34px;
    }

    .switcher {
        background-color: #2C2C30;
        height: $switcher-height;
        width: $switcher-width;
        border-radius: $switcher-height / 2;
        transition: 300ms;
        position: relative;

        &:after {
            background-color: #FFF;
            content: '';
            display: block;
            position: absolute;
            width: $slider-size;
            height: $slider-size;
            border-radius: 50%;
            left: $padding;
            top: 50%;
            transform: translateY(-50%);
            transition: 300ms;
        }
    }

    &.active {
        .switcher {
            background-color: #7A64E2;

            &:after {
                left: $padding + $amplitude;
            }
        }
    }
}
</style>
