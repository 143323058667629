const BANK131_SCRIPT_URL = 'https://widget.bank131.ru/payment-form.js';
const TEST_BANK131_SCRIPT_URL = 'https://widget-demo.bank131.ru/payment-form.js';

class Bank131Client {
    constructor(env) {
        this.env = env;
        this.loaded = false;
        this.token = null;
        this.listeners = {};
    }

    init(listeners) {
        const isTest = this.env !== 'production';
        const scriptUrl = isTest ? TEST_BANK131_SCRIPT_URL : BANK131_SCRIPT_URL;
        const script = document.createElement('script');

        this.listeners = listeners;

        script.setAttribute('src', scriptUrl);
        script.onload = () => {
            this.loaded = true;
            if (this.token) {
                this.render();
            }
        };
        document.head.appendChild(script);
    }

    setTokenAndTryRender(token) {
        this.token = token;
        if (this.loaded) {
            this.render();
        }
    }

    render() {
        const paymentForm = new window.Bank131PaymentForm(this.token, {
            isCvcMasked: true,
            texts: {
                paymentForm: {
                    buttonPayLabel: 'Продолжить',

                    cardholderLabel: 'Владелец карты',
                    cardholderNote: '',
                    cardholderPlaceholder: 'ИМЯ ФАМИЛИЯ',

                    cardNumberLabel: '',
                    cardNumberNote: '',
                    cardNumberPlaceholder: '0000 0000 0000 0000',

                    cvvLabel: 'CVC',
                    cvvNote: '',
                    cvvPlaceholder: 'CVC',

                    expireDateLabel: 'Срок действия',
                    expireDateNote: '',
                    expireDatePlaceholder: '00/00',

                    recurrentLabel: 'Соглашаюсь на автоплатежи',

                    termsAgreement:
                        'Нажимая кнопку «Оплатить» вы соглашаетесь с условиями {{#link}}пользовательского соглашения{{/link}}',

                    validationErrors: {
                        INVALID_CARD_NUMBER: 'Неверный номер карты',
                        INVALID_CVV: 'Код должен содержать 3 цифры',
                        INVALID_EXPIRY_DATE: 'Неверная дата',
                        IS_REQUIRED: 'Обязательное поле',
                    },
                },
                unknownError: 'Произошла ошибка. Попробуйте снова'
            }
        });
        const {onReady, onPaymentStart, onPaymentSuccess, onPaymentFail} = this.listeners;

        paymentForm.onReady = onReady;
        paymentForm.onPaymentStart = onPaymentStart;
        paymentForm.onPaymentSuccess = onPaymentSuccess;
        paymentForm.onPaymentFail = onPaymentFail;
        paymentForm.render();
    }
}

window.bank131Client = window.bank131Client || new Bank131Client(process.env.VUE_APP_ENV);

const PaymentService = {
    bank131Client: window.bank131Client
};

export default PaymentService;
