import ApiService from "/src-new-version/services/ApiService";

const CHECK_AUTH_URL = process.env.VUE_APP_APPLEID_AUTH_CHECK_URI;

export const checkAuth = async (token) => {
    const {data} = await ApiService.anonInstance.post(CHECK_AUTH_URL, null, {
        headers: {
            'Auth-Token': token
        }
    });
    return data.oauth_id;
};
