import {createApp, h} from "vue";
import App from './App';
import router from '/src-new-version/app/router';
import {i18n} from '/src-new-version/app/i18n';
import {authActions} from '/src-new-version/modules/auth';
import {onelinkActions} from '/src-new-version/modules/onelink';
import Toast from "vue-toastification";

const app = createApp({
    render: () => h(App)
});

app.use(router);
app.use(i18n);
app.use(Toast, {
    // timeout: 0,
    position: 'bottom-center',
    transition: 'Vue-Toastification__slideBlurred',
    closeButton: false,
    icon: false,
    hideProgressBar: true
});

authActions.checkSpecial();
onelinkActions.checkStorageTimeout();

if (process.env.VUE_APP_ENV !== "staging" || localStorage.special === process.env.VUE_APP_SPECIAL_TOKEN) {
    app.mount("#app");
}
