import {useBillingStore} from "../store/billingStore";

export const setSelectedProduct = (productState) => {
    localStorage.setItem("productState", JSON.stringify(productState));
    useBillingStore.setState(productState);
};

export const clearSelectedProduct = () => {
    localStorage.removeItem("productState");
};

export const saveSubscribedStatus = () => {
    localStorage.setItem('status', "su");
};
