import {onMounted, ref} from 'vue';
import {getPrices} from "../api/billingApi";
import {i18n} from '/src-new-version/app/i18n';
import {routerActions} from '/src-new-version/app/router';


const usePrices = () => {
    const {t, tc} = i18n.global;

    const prices = ref();
    const priceTextYear = ref('');
    const priceTextMonth = ref('');
    const discountTextYear = ref('');
    const isTrialAvailable = ref(false);
    const trialSubtitleText = ref('');

    const fillData = (data) => {
        if (data.year.price < (data.month.price * 12)) {
            const discount = 1 - (data.year.price / (data.month.price * 12));
            discountTextYear.value = `${t('common.discount')} ${Math.round(discount * 100)}%`;
        }
        priceTextYear.value = t('subscription.price_label_year', {
            price: data.year.price,
            perMonth: Math.round(data.year.price / 12)
        });
        priceTextMonth.value = t('subscription.price_label_month', {price: data.month.price});
        isTrialAvailable.value = !!data.year.trial_days;
        if (isTrialAvailable.value) {
            const days = data.year.trial_days;
            trialSubtitleText.value = tc('subscription.trial_days', days, {days});
        }
    };

    onMounted(async () => {
        try {
            const data = await getPrices();
            prices.value = data;
            if (!data) {
                return;
            }
            fillData(data);
        } catch (e) {
            if (e.message === 'Missing Oauth ID') {
                routerActions.routeTo('Signup');
            }
        }
    });

    return {
        prices,
        priceTextYear,
        priceTextMonth,
        discountTextYear,
        isTrialAvailable,
        trialSubtitleText
    };
};

export default usePrices;
