import {useToast} from 'vue-toastification';
import "vue-toastification/dist/index.css";
import "./../style/notification-styles.scss";

const useNotifications = () => {
    const toast = useToast();

    const notifySuccess = (msg) => {
        toast.success(msg);
    };

    const notifyError = (msg) => {
        toast.error(msg);
    };

    return {
        notifySuccess,
        notifyError
    };
};

export default useNotifications;
